<template>
  <div class="container">
    <!-- Main Card -->
    <div v-aos="fade-up">
      <div class="card-title">
        <h1>{{ $t('title1000') }}</h1>
      </div>

      <!-- Department Overview -->
      <div class="mainn_h2 card">
        <h2>{{ $t('departmentOverview1001') }}</h2>
      </div>

      <!-- Diagnostic Methods -->
      <div class="card-section card">
        <h2>{{ $t('diagnosticMethods1002.header') }}</h2>
        <p>{{ $t('diagnosticMethods1002.xRay') }}</p>
        <p>{{ $t('diagnosticMethods1002.ctScan') }}</p>
        <p>{{ $t('diagnosticMethods1002.angiography') }}</p>
        <p>{{ $t('diagnosticMethods1002.mri') }}</p>
        <p>{{ $t('diagnosticMethods1002.ultrasound') }}</p>
      </div>

      <div>
        <div class="img_card">
          <img :src="img1" alt="">
          <img :src="img2" alt="">
          <img :src="img3" alt="">
        </div>
        <div class="img_card">
          <img :src="img4" alt="">
          <img :src="img5" alt="">
          <img :src="img6" alt="">
        </div>
      </div>

      <!-- Radiotherapeutic Methods -->
      <div class="card-section card">
        <h2>{{ $t('radiotherapyMethods1003.header') }}</h2>
        <ul>
          <li>{{ $t('radiotherapyMethods1003.methods[0]') }}</li>
          <li>{{ $t('radiotherapyMethods1003.methods[1]') }}</li>
          <li>{{ $t('radiotherapyMethods1003.methods[2]') }}</li>
        </ul>
      </div>

      <!-- Department Head Section -->
      <div class="department-head card">
        <h2>{{ $t('departmentHead1004.header') }}</h2>
        <div class="head-info">
          <img :src="mudur" alt="Department Head" class="head-img">
          <div class="head-details">
            <p><strong>{{ $t('departmentHead1004.name') }}</strong></p>
            <p>{{ $t('departmentHead1004.title') }}</p>
          </div>
        </div>
      </div>

      <!-- Doctor List -->
      <div class="card-section">
        <h2>{{ $t('doctors1005.header') }}</h2>
        <div class="doctor-list">
          <div v-for="(doctor, index) in doctors" :key="index" class="doctor-card">
            <h3>{{ doctor.name }}</h3>
            <p>{{ doctor.specialty }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mudur from '@/assets/nurli/mudur.png'
import img1 from "@/assets/nurli/nurli1.png"
import img2 from "@/assets/nurli/nurli2.png"
import img3 from "@/assets/nurli/nurli3.png"
import img4 from "@/assets/nurli/nurli4.png"
import img5 from "@/assets/nurli/nurli5.png"
import img6 from "@/assets/nurli/nurli6.png"

export default {
  data() {
    return {
      doctors: [
        { name: "Shirinov Mustafo Muminovich", specialty: "Rentgenolog" },
        { name: "Qo`shayev Shaxob Shodiyevich", specialty: "UTT vrachi" },
        { name: "Ergashev Nodir Rustamovich", specialty: "Radiolog" },
        { name: "Ramazonov Ramz Raxmatovich", specialty: "Rentgenolog" },
        { name: "Shukurov Zamon Umarovich", specialty: "Radiolog" },
        { name: "Barnoev Axtam Istamovich", specialty: "UTT vrachi" },
        { name: "Murodov Yusuf Jo`raboyevich", specialty: "Radiolog" },
        { name: "Tog`ayev Jo`rabek Faxriddinovich", specialty: "Radiolog" },
        { name: "Rashitova Albina Anvarovna", specialty: "Radiolog" },
        { name: "Eshpulatov Elbek Yarashevich", specialty: "Rentgenolog" },
        { name: "Murodov Nozim Xakim o`g`li", specialty: "Tibbiy fizik" },
        { name: "Shamsiddinov Abdulaziz Zafar o`g`li", specialty: "Tibbiy fizik" },
        { name: "Muzaffarov Faxriddin Muxiddinovich", specialty: "Dozimetrist" }
      ],
      mudur,
      img1,
      img2,
      img3,
      img4,
      img5,
      img6
    };
  }
};
</script>

<style scoped>
.card {
  background-color: #f9fafb;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card-title h1 {
  text-align: center;
  font-size: 3rem;
  color: #003366;
  font-weight: 600;
  margin-bottom: 30px;
}

.card-section h2 {
  font-size: 1.75rem;
  color: #2980b9;
  margin-bottom: 10px;
}

.mainn_h2{
  color: #000;
  font-size: 1.75rem;
}

.card-section p {
  font-size: 1.1rem;
  color: #34495e;
  line-height: 1.6;
}

.card-section ul {
  padding-left: 20px;
}

.card-section ul li {
  font-size: 1.1rem;
  color: #34495e;
  margin-bottom: 8px;
}

/* Department Head Section */
.department-head {
  margin-top: 40px;
}

.head-info {
  display: flex;
  gap: 20px;
  align-items: center;
}

.head-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #ddd;
}

.head-details {
  font-size: 1.2rem;
}

.head-details p {
  margin: 5px 0;
}

/* Doctor list styling */
.doctor-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.doctor-card {
  width: calc(33.33% - 20px);
  background-color: #f0f4f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.doctor-card h3 {
  font-size: 1.25rem;
  color: #2c3e50;
}

.doctor-card p {
  font-size: 1rem;
  color: #7f8c8d;
  margin-top: 5px;
}

/* Technologies Section */
.technologies h2 {
  font-size: 1.75rem;
  color: #2980b9;
  margin-bottom: 20px;
}

.technology-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.technology-card {
  width: calc(20% - 20px);
  background-color: #f0f4f7;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.technology-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.technology-card p {
  margin-top: 10px;
  color: #34495e;
}

.technology-cards img{
  height: 250px;
}

/* Responsive Images */
.img_card {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.img_card img {
  width: calc(33.33% - 20px);
  max-width: 100%;
  height: 500px;
  object-fit: cover;
  transition: transform 0.3s ease, opacity 0.3s ease;
  padding: 20px;
}

.img_card img:hover {
  transform: scale(1.05);
  opacity: 0.9;
}

@media (max-width: 768px) {
  .doctor-card {
    width: 100%;
  }

  .technology-card {
    width: calc(50% - 20px);
  }

  .department-head .head-info {
    flex-direction: column;
    align-items: center;
  }

  .head-img {
    width: 120px;
    height: 120px;
  }

  .card-title h1 {
    font-size: 2rem;
  }

  .technology-cards img {
    height: 150px;
  }

  .img_card img {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .card-title h1 {
    font-size: 1.5rem;
  }

  .card-section h2 {
    font-size: 1.25rem;
  }

  .card-section p,
  .card-section ul li {
    font-size: 1rem;
  }

  .doctor-card {
    width: 100%;
  }

  .doctor-card h3 {
    font-size: 1.1rem;
  }

  .doctor-card p {
    font-size: 0.9rem;
  }
}


</style>
