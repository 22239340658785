<template>
  <div class="container medical-department">
    <section class="department-header">
      <h1>{{$t('section1.title1')}}</h1>
      <p class="main_p">{{$t('section1.description1')}}</p>
    </section>

    <section class="department">
      <h2>{{$t('department1.title2')}}</h2>
      <p class="description">{{$t('department1.description1')}}</p>

      <div class="card">
        <h3>{{$t ("diagnostica1")}}</h3>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics1.diagnostic_item1.title3")}}</h4>
          <p>{{$t("diagnostics1.diagnostic_item1.description1")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics1.diagnostic_item2.title4")}}</h4>
          <p>{{$t("diagnostics1.diagnostic_item2.description2")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics1.diagnostic_item3.title5")}}</h4>
          <p>{{$t("diagnostics1.diagnostic_item3.description3")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics1.diagnostic_item4.title6")}}</h4>
          <p>{{$t("diagnostics1.diagnostic_item4.description4")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics1.diagnostic_item5.title7")}}</h4>
          <p>{{$t("diagnostics1.diagnostic_item5.description5")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics1.diagnostic_item6.title8")}}</h4>
          <p>{{$t("diagnostics1.diagnostic_item6.description6")}}</p>
        </div>
      </div>
    </section>

    <section class="department">
      <h2>{{$t("department2.title9")}}</h2>

      <div class="card">
        <div class="diagnostic-item">
          <h4>{{$t("department2.surgery_item1.title10")}}</h4>
          <p>{{$t("department2.surgery_item1.description7")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("department2.surgery_item2.title11")}}</h4>
          <p>{{$t("department2.surgery_item2.description8")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("department2.surgery_item3.title12")}}</h4>
          <p>{{$t("department2.surgery_item3.description9")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("department2.surgery_item4.title13")}}</h4>
          <p>{{$t("department2.surgery_item4.description10")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("department2.surgery_item5.title14")}}</h4>
          <p>{{$t("department2.surgery_item5.description11")}}</p>
        </div>
      </div>
    </section>

    <section class="department">
      <p class="description">{{$t("department3.description12")}}</p>

      <div class="card">
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics2.diagnostic_item1.title16")}}</h4>
          <p>{{$t("diagnostics2.diagnostic_item1.description13")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics2.diagnostic_item2.title17")}}</h4>
          <p>{{$t("diagnostics2.diagnostic_item2.description14")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics2.diagnostic_item3.title18")}}</h4>
          <p>{{$t("diagnostics2.diagnostic_item3.description15")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics2.diagnostic_item4.title19")}}</h4>
          <p>{{$t("diagnostics2.diagnostic_item4.description16")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics2.diagnostic_item5.title20")}}</h4>
          <p>{{$t("diagnostics2.diagnostic_item5.description17")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics2.diagnostic_item6.title21")}}</h4>
          <p>{{$t("diagnostics2.diagnostic_item6.description18")}}</p>
        </div>
      </div>
    </section>

    <section class="department">
      <h2>{{$t("department4.title22")}}</h2>
      <p class="description">{{$t("department4.description19")}}</p>

      <div class="card">
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics3.diagnostic_item1.title23")}}</h4>
          <p>{{$t("diagnostics3.diagnostic_item1.description20")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics3.diagnostic_item2.title24")}}</h4>
          <p>{{$t("diagnostics3.diagnostic_item2.description21")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics3.diagnostic_item3.title25")}}</h4>
          <p>{{$t("diagnostics3.diagnostic_item3.description22")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics3.diagnostic_item4.title26")}}</h4>
          <p>{{$t("diagnostics3.diagnostic_item4.description23")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics3.diagnostic_item5.title27")}}</h4>
          <p>{{$t("diagnostics3.diagnostic_item5.description24")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics3.diagnostic_item6.title28")}}</h4>
          <p>{{$t("diagnostics3.diagnostic_item6.description25")}}</p>
        </div>
        <div class="diagnostic-item">
          <h4>{{$t("diagnostics3.diagnostic_item7.title29")}}</h4>
          <p>{{$t("diagnostics3.diagnostic_item7.description26")}}</p>
        </div>
      </div>
    </section>

    <section class="department">
      <h2>{{$t("department5.title30")}}</h2>
      <p class="description">{{$t("department5.description27")}}</p>
    </section>

    <!-- New section with your information -->
    <section class="department director-info">
      <h2>{{$t('experts')}}</h2>
      <div class="card">
        <div class="team-member">
          <h4>{{$t('team_member_1.name')}}</h4>
          <p>{{$t('team_member_1.description')}}</p>
        </div>
        <div class="team-member">
          <h4>{{$t('team_member_2.name')}}</h4>
          <p>{{$t('team_member_2.description')}}</p>
        </div>
        <div class="team-member">
          <h4>{{$t('team_member_3.name')}}</h4>
          <p>{{$t('team_member_3.description')}}</p>
        </div>
        <div class="team-member">
          <h4>{{$t('team_member_4.name')}}</h4>
        </div>
        <div class="team-member">
          <h4>{{$t('team_member_5.name')}}</h4>
        </div>
        <div class="team-member">
          <h4>{{$t('team_member_6.name')}}</h4>
        </div>
        <div class="team-member">
          <h4>{{$t('team_member_7.name')}}</h4>
        </div>
        <div class="team-member">
          <h4>{{$t('team_member_8.name')}}</h4>
        </div>
        <div class="team-member">
          <h4>{{$t('team_member_9.name')}}</h4>
        </div>

        <div class="team-member">
          <h4>{{$t('team_member_10.name')}}</h4>
        </div>
      </div>
    </section>    
  </div>
</template>

<script>
export default {
  name: 'MedicalDepartments',
}
</script>

<style scoped>
.medical-department {
  padding: 20px;
  color: #333;
}

.department-header {
  text-align: center;
  margin-bottom: 30px;
}

.department-header h1 {
  font-size: 3rem;
  color: #003366;
  font-weight: 600;
}

.department {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.department h2 {
  font-size: 28px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.main_p {
  align-items: center;
  font-size: 20px;
}

.description {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 20px;
}

.card {
  background-color: #fafafa;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card h3 {
  font-size: 22px;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
}

.diagnostic-item {
  margin-bottom: 15px;
}

.diagnostic-item h4 {
  font-size: 18px;
  color: #4A90E2;
  font-weight: 600;
  margin-bottom: 5px;
}

.diagnostic-item p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .department {
    padding: 15px;
  }

  .department-header h1 {
    font-size: 28px;
  }

  .department h2 {
    font-size: 24px;
  }

  .card h3 {
    font-size: 20px;
  }

  .diagnostic-item h4 {
    font-size: 16px;
  }
}

/* Styling for the new "Our Experts" section */
.director-info {
  margin-top: 40px;
}

.team-member {
  margin-bottom: 20px;
}

.team-member h4 {
  font-size: 20px;
  color: #333;
  font-weight: 600;
}

.team-member p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

/* Animation */
.team-member {
  opacity: 0;
  animation: fadeInUp 0.5s forwards;
}

.team-member:nth-child(odd) {
  animation-delay: 0.2s;
}

.team-member:nth-child(even) {
  animation-delay: 0.4s;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
