<template>
    <div class="container">
        <p class="mainh1">{{$t('header_title')}}</p>
        <p class="intro">
          {{$t('introo')}}
        </p>
      <div class="main">
        <div class="left">
          <div class="image-container">
            <img :src="img1" alt="Surgical Department" class="img" />
          </div>
        </div>
        
        <div class="right">
          
          <ul class="operations">
            <li>{{$t('kidney_cancer')}}</li>
            <li>{{$t('ureter_cancer')}}</li>
            <li>{{$t('prostate_cancer')}}</li>
            <li>{{$t('bladder_cancer')}}</li>
            <li>{{$t('penis_cancer')}}</li>
            <li>{{$t('testicular_cancer')}}</li>
            <li>{{$t('adrenal_cancer')}}</li>
          </ul>
        </div>
      </div>
      <div class="main2">
        <div>
            <h1>
              {{$t('doctor_question')}} 
            </h1>
            <div class="urology-tests">
              <div class="container">
                <section class="tests">
                  <div class="list-group">
                    <div class="list-group-item">
                      <h2 class="mb-3 mainh5">{{$t('test_name')}}</h2>
                      <p>{{$t('test_description')}}</p>
                    </div>
                    <div class="list-group-item">
                      <p class="mb-3 mainh5">{{$t('test_name1')}}</p>
                      <p>{{$t('test_description1')}}</p>
                    </div>
                    <div class="list-group-item">
                      <p  class="mb-3 mainh5">{{$t('test_name2')}}</p>
                      <p>{{$t('test_description2')}}</p>
                    </div>
                    <div class="list-group-item">
                      <p class="mb-3 mainh5">{{$t('test_name3')}}</p>
                      <p>{{$t('test_description3')}}</p>
                    </div>
                    <div class="list-group-item">
                      <p class="mb-3 mainh5">{{$t('test_name4')}}</p>
                      <p>{{$t('test_description4')}}</p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          
        </div>
      </div>

      
      <div class="abdominal-onkoxirurgiya">
        <h1 class="text-center">{{$t('ABDOMINAL')}}</h1>
        <p>
          {{ $t('named1') }}
        </p>
        <p>
          {{ $t('named2') }}
        </p>
        
        <h2 class="text-center">{{$t("named3")}}</h2>
        <ul>
          <li>{{$t('named39')}}</li>
          <li>{{$t('named38')}}</li>
          <li>{{$t('named37')}}</li>
        </ul>
        
        <h2 class="text-center">{{$t('named36')}}</h2>
        <ul>
          <li>{{$t('named35')}}</li>
          <li>{{$t('named34')}}</li>
          <li>{{$t('named33')}}</li>
          <li>{{$t('named32')}}</li>
          <li>{{$t('named31')}}</li>
          <li>{{$t('named30')}}</li>
          <li>{{$t('named29')}}</li>
          <li>{{$t('named28')}}</li>
          <li>{{$t('named40')}}</li>
          <li>{{$t('named27')}}</li>
          <li>{{$t('named26')}}</li>
          <li>{{$t('named25')}}</li>
          <li>{{$t('named24')}}</li>
          <li>{{$t('named23')}}</li>
          <li>{{$t('named22')}}</li>
          <li>{{$t('named21')}}</li>
          <li>{{$t('named20')}}</li>
          <li>{{$t('named19')}}</li>
        </ul>
        
        <p>{{$t('named18')}}</p>
      </div>


      <div class="koloproktologiya">
        <h1 class="text-center">{{$t('named17')}}</h1>
        <section>
            <p class="text-center">{{$t('named16')}}</p>
        </section>
    
        <section>
          <p>{{$t('named5')}}</p>
          <p>{{$t('named4')}}</p>
        </section>
    
        <section>
          <h2 class="text-center"></h2>
          <p>{{$t('named6')}}</p>{{$t('named10')}}
          <p>{{$t('named7')}}</p>
          <p>{{$t('named8')}}</p>
          <p>{{$t('named9')}}</p>
        </section>
    
        <section>
          <h2 class="text-center">{{$t('named11')}}</h2>
          <p>{{$t('named12')}}</p>
          <p>{{$t('named13')}}</p>
          <p>{{$t('named14')}}</p>
          <p>{{$t('named15')}}</p>
        </section>
      </div>
    

    </div>
  </template>
  
  <script>
    import img1 from '@/assets/jarrohlik3.jpg'
    import img2 from '@/assets/jarrohlik2.jpg'
    import img3 from '@/assets/jarrohlik4.jpg'
    export default {
      data() {
        return {
          img1,
          img2,
          img3
        }
      }
    }
  </script>
  
  <style scoped>
  .main {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 40px;
  }

  .main2 {
    display: flex;
    margin-top: 30px;
  }

  .main3 {
    display: flex;
    margin-top: 30px;
  }

  .mainh1 {
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
    color: #003366;
  }

  .intro {
    text-align: center;
    font-size: 22px;
    color: black;
    font-weight: 400;
  }

  .left .image-container {
    max-width: 100%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  img {
    width: 700px;
    height: 700px;
    height: auto;
    border-radius: 20px;
  }

  .right {
    flex: 1;
    max-width: 800px;
    line-height: 1.7;
    color: #444;
  }

  .right .title {
    font-size: 2.2rem;
    color: #2d2d2d;
    margin-bottom: 20px;
  }

  .right .intro {
    font-size: 1.1rem;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .right .operations {
    list-style: none;
    padding-left: 20px;
  }

  .right .operations li {
    font-size: 15px;
    margin-bottom: 15px;
  }

  .right .operations li strong {
    color: #1a73e8;
  }

  .right .additional-info {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }

  .right a {
    color: #1a73e8;
    text-decoration: none;
  }

  .right a:hover {
    text-decoration: underline;
  }

  .main2 h1 {
    font-size: 16px;
    color: #444444;
    font-weight: 400;
    line-height: 2;
  }

  .main2 {
    gap: 20px;
  }

  .main3 {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
    margin-top: 50px;
    padding: 0 20px;
  }

  .main3-image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .main3-left {
    flex: 1;
    font-size: 1.1rem;
    line-height: 1.8;
    color: #444;
    padding: 0 20px;
  }

  .main3-left h1 {
    font-size: 1.2rem;
    font-weight: 400;
    color: #333;
    margin-bottom: 20px;
    text-align: justify;
  }

  .urology-tests {
    padding: 30px 0;
  }

  h1 {
    font-size: 2.5rem;
    color: #003366;
    font-weight: 600;
  }

  h2 {
    font-size: 2rem;
    color: #003366;
    margin-bottom: 20px;
  }

  p {
    font-size: 1rem;
    color: #555;
  }

  .card {
    border-radius: 10px;
  }

  .card-header {
    background-color: #003366;
    color: white;
    font-weight: bold;
  }

  .card-body {
    background-color: #f0f8ff;
  }

  ul {
    padding-left: 20px;
  }

  .list-group-item {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .list-group-item h5 {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .list-group-item p {
    font-size: 1rem;
    color: #666;
  }

  header {
    background-color: #e0f7fa;
    padding: 50px 0;
    border-radius: 10px;
  }

  header h1 {
    font-size: 3rem;
  }

  header .lead {
    font-size: 1.25rem;
    font-weight: 400;
  }

  footer {
    background-color: #003366;
    color: white;
    text-align: center;
    padding: 20px 0;
  }

  .mainh5 {
    font-size: 42px;
    font-weight: 500;
    color: black;
  }

  /* Responsive Design */
  @media (max-width: 1024px) {
    .main {
      flex-direction: column;
      align-items: center;
    }

    .mainh1 {
      font-size: 2.5rem;
    }

    .left .image-container img {
      width: 100%;
      max-width: 600px;
    }

    .right {
      max-width: 100%;
      margin-top: 20px;
    }

    .operations {
      font-size: 14px;
    }

    .main2 {
      flex-direction: column;
      gap: 20px;
    }

    .main2 h1 {
      font-size: 18px;
      text-align: center;
    }

    .main3 {
      flex-direction: column;
      gap: 20px;
    }

    .main3-image-container img {
      width: 100%;
      height: auto;
    }

    .main3-left {
      padding: 0;
    }
  }

  @media (max-width: 768px) {
    .main {
      flex-direction: column;
      align-items: center;
    }

    .mainh1 {
      font-size: 2rem;
    }

    .left .image-container img {
      width: 100%;
      max-width: 500px;
    }

    .right {
      max-width: 100%;
    }

    .operations {
      font-size: 12px;
    }

    .main2 h1 {
      font-size: 16px;
      text-align: center;
    }

    .main2 {
      gap: 20px;
    }

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.8rem;
    }

    p {
      font-size: 1rem;
    }
  }

  @media (max-width: 480px) {
    .mainh1 {
      font-size: 1.8rem;
    }

    .intro {
      font-size: 18px;
    }

    .left .image-container img {
      width: 100%;
      max-width: 400px;
    }

    .main2 h1 {
      font-size: 14px;
    }

    .operations {
      font-size: 11px;
    }

    h1 {
      font-size: 1.8rem;
    }

    h2 {
      font-size: 1.6rem;
    }

    p {
      font-size: 0.9rem;
    }
  }
</style>

  