<template>
  <div class="container">
    <section class="section poliklinika">
      <div class="section-header">
        <h2>{{ $t('poliklinika.title') }}</h2>
      </div>
      <p class="text-center mainP">{{ $t('poliklinika.description1') }}</p>
      <p class="text-center mainP">{{ $t('poliklinika.description2') }}</p>
    </section>

    <section class="section">
      <div class="section-header">
        <h2>{{ $t('director.title') }}</h2>
      </div>
      <div class="card">
        <div>
          <img :src="im1" alt="Maqsudova Nasiba Bakayevna" class="specialist">
        </div>
        <div>
          <h3>{{ $t('director.name') }}</h3>
          <p>{{ $t('director.specialization') }}</p>
          <p>{{ $t('contact') }}: <strong>+998914095055</strong></p>
        </div>
      </div>
    </section>

    <section class="specialists">
      <div class="section-header">
        <h2>{{ $t('specialists1.title') }}</h2>
      </div>
      <div class="specialists-container">
        <div class="specialist-card" v-for="(specialist, index) in specialists" :key="index">
          <img :src="specialist.src" :alt="specialist.name" class="worker">
          <h3 class="workerName">{{ specialist.name }}</h3>
          <p>{{ specialist.specialization }}</p>
          <p>{{ $t('contact') }}: <strong>{{ specialist.phone }}</strong></p>
        </div>
      </div>
    </section>

    <section class="section poliklinika">
      <div class="section-header">
        <h2>{{ $t("poliklinika1.title") }}</h2>
      </div>
      <p>
        {{ $t("poliklinika1.text1") }}
      </p>
      <p>
        {{ $t("poliklinika1.text2") }}
      </p>
      <p>{{ $t("poliklinika1.text3") }}</p>
      <p>{{ $t("poliklinika1.text4") }}</p>
    </section>
    
    <section class="consultation">
      <div class="section-header">
        <h2>{{ $t("consultation1.title") }}</h2>
      </div>
      <p>
        {{ $t("consultation1.text") }}
      </p>
      <h3>{{ $t("consultation1.specialists") }}</h3>
      <ul>
        <li>{{ $t("consultation1.specialists_list") }}</li>
        <li>{{ $t("consultation1.specialists_list2") }}</li>
        <li>{{ $t("consultation1.specialists_list3") }}</li>
        <li>{{ $t("consultation1.specialists_list4") }}</li>
        <li>{{ $t("consultation1.specialists_list5") }}</li>
        <li>{{ $t("consultation1.specialists_list6") }}</li>
        <li>{{ $t("consultation1.specialists_list7") }}</li>
        <li>{{ $t("consultation1.specialists_list8") }}</li>
        <li>{{ $t("consultation1.specialists_list9") }}</li>
        <li>{{ $t("consultation1.specialists_list10") }}</li>
        <li>{{ $t("consultation1.specialists_list11") }}</li>
      </ul>
    </section>
    
    <section class="chemotherapy">
      <div class="section-header">
        <h2>{{ $t("chemotherapy1.title") }}</h2>
      </div>
      <p>
        {{ $t("chemotherapy1.text1") }}
      </p>
      <h3>{{ $t("chemotherapy1.text2") }}</h3>
      <ul>
        <li>{{ $t("chemotherapy1.diseases_list1") }}</li>
        <li>{{ $t("chemotherapy1.diseases_list2") }}</li>
        <li>{{ $t("chemotherapy1.diseases_list3") }}</li>
        <li>{{ $t("chemotherapy1.diseases_list4") }}</li>
        <li>{{ $t("chemotherapy1.diseases_list5") }}</li>
        <li>{{ $t("chemotherapy1.diseases_list6") }}</li>
        <li>{{ $t("chemotherapy1.diseases_list7") }}</li>
        <li>{{ $t("chemotherapy1.diseases_list8") }}</li>
        <li>{{ $t("chemotherapy1.diseases_list9") }}</li>
        <li>{{ $t("chemotherapy1.diseases_list10") }}</li>
      </ul>
    </section>
    
    <section class="diagnostics">
      <div class="section-header">
        <h2>{{ $t("diagnostics.title") }}</h2>
      </div>
      <p>
        {{ $t("diagnostics.text1") }}
      </p>
      <p>
        {{ $t("diagnostics.text2") }}
      </p>
      <ul>
        <li>{{ $t("diagnostics.services_list1") }}</li>
        <li>{{ $t("diagnostics.services_list2") }}</li>
      </ul>
    </section>
    
  </div>
</template>

<script>
import im1 from "@/assets/ploklinika/mudur.png"; 
import im2 from "@/assets/ploklinika/polik1.png";
import im3 from "@/assets/ploklinika/polik2.png";
import im4 from "@/assets/ploklinika/polik3.png";
import im5 from "@/assets/ploklinika/polik4.png";
import im6 from "@/assets/ploklinika/polik5.png";
import im7 from "@/assets/ploklinika/polik6.png";
import im8 from "@/assets/ploklinika/polik7.png";
import im9 from "@/assets/ploklinika/polik8.png";
import im10 from "@/assets/ploklinika/polik9.png";

export default {
  name: 'BuxoroOncologyCenter',
  data() {
    return {
      specialists: [
        { src: im2, name: this.$t('specialists1.roziyevAkmal.name'), specialization: this.$t('specialists1.roziyevAkmal.specialization'), phone: "+998910801030" },
        { src: im3, name: this.$t('specialists1.sharipovMirshod.name'), specialization: this.$t('specialists1.sharipovMirshod.specialization'), phone: "+998913108713" },
        { src: im4, name: this.$t('specialists1.hasanovaNasiba.name'), specialization: this.$t('specialists1.hasanovaNasiba.specialization'), phone: "+998973012900" },
        { src: im5, name: this.$t('specialists1.sharipovaSanobar.name'), specialization: this.$t('specialists1.sharipovaSanobar.specialization'), phone: "+99890211556" },
        { src: im6, name: this.$t('specialists1.atabayevaDilsuz.name'), specialization: this.$t('specialists1.atabayevaDilsuz.specialization'), phone: "+998936522678" },
        { src: im7, name: this.$t('specialists1.latipovBahodir.name'), specialization: this.$t('specialists1.latipovBahodir.specialization'), phone: "+998907154333" },
        { src: im8, name: this.$t('specialists1.islomovaMuharram.name'), specialization: this.$t('specialists1.islomovaMuharram.specialization'), phone: "+998930831376" },
        { src: im9, name: this.$t('specialists1.abdullayevErkinjon.name'), specialization: this.$t('specialists1.abdullayevErkinjon.specialization'), phone: "" },
        { src: im10, name: this.$t('specialists1.ruziyevaShaxlo.name'), specialization: this.$t('specialists1.ruziyevaShaxlo.specialization'), phone: "" }
      ],
      im1
    };
  }
};
</script>

<style scoped>
/* Your CSS remains unchanged */
</style>


<style scoped>

.header {
  text-align: center;
  margin-bottom: 30px;
}

.header h1 {
  color: #333;
  font-size: 2.5rem;
}

.section-header {
  margin-bottom: 20px;
  text-align: center;
}

.mainP{
  font-size: 20px;
}

.worker{
  width: 130px;
}

.workerName{
  text-align: center;
}

h2 {
  font-size: 1.8rem;
  color: #2c3e50;
}

h3 {
  font-size: 1.5rem;
  color: #34495e;
}

.card{
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  text-align: center;
  padding: 10px;
}

.card img {
  width: 800px;
  height: 500px;
  object-fit: cover;
  margin-bottom: 20px;
}

.card{
  background-color: #fff;
  padding: 15px;
  margin: 15px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 700px;
  width: 100%;
}

 .specialist-card {
  background-color: #fff;
  padding: 15px;
  margin: 15px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 700px;
  width: 300px;
}

.specialist-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  padding-top: 50px;
}

.specialist-card p {
  font-size: 1rem;
  margin-bottom: 5px;
  text-align: center;
}

.specialists-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.specialist-card {
  flex: 0 0 30%;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.specialist-card img {
  width: 100%;
  height: 450px;
  border-radius: 5px;
}

.specialist-card:hover {
  transform: translateY(-5px);
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  margin-bottom: 5px;
  font-size: 1rem;
  color: #555;
}

@media (max-width: 1024px) {
  .specialist-card {
    flex: 1 1 calc(50% - 20px);
    margin: 5px;
  }


  .card img {
    width: 100%;
  }
}



@media (max-width: 600px) {
  .specialist-card {
    flex: 1 1 100%;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  .mainP {
    font-size: 1rem;
  }
}
</style>
