<template>
  <div class="app">
    <section class="header">
      <h1 class="mainn_h1" v-animation="fadeIn">{{ $t('number100') }}</h1>
      <h2 v-animation="fadeIn">{{ $t('number101') }}</h2>
    </section>

    <transition name="fade" mode="out-in">
      <section>
        <h2 class="main_h2">{{ $t('number102') }}</h2>
      </section>
    </transition>

    <transition name="fade" mode="out-in">
      <section class="diagnostics card">
        <h3 class="section-title">{{ $t('number103') }}</h3>
        <div class="jar_info">
          <div>
            <ul>
              <li>{{ $t('number105') }}</li>
              <li>{{ $t('number106') }}</li>
              <li>{{ $t('number107') }}</li>
              <li>{{ $t('number108') }}</li>
            </ul>
          </div>
          <div>
            <ul>
              <li>{{ $t('number109') }}</li>
              <li>{{ $t('number110') }}</li>
              <li>{{ $t('number111') }}</li>
              <li>{{ $t('number112') }}</li>
            </ul>
          </div>
          <div>
            <ul>
              <li>{{ $t('number113') }}</li>
              <li>{{ $t('number114') }}</li>
            </ul>
          </div>
        </div>
      </section>
    </transition>

    <transition name="fade" mode="out-in">
      <section class="procedures card">
        <h3 class="section-title">{{ $t('number115') }}</h3>
        <p>{{ $t('number116') }}</p>
        <h4 class="subsection-title">{{ $t('number117') }}</h4>
        <ul>
          <li>{{ $t('number118') }}</li>
          <li>{{ $t('number119') }}</li>
          <li>{{ $t('number120') }}</li>
          <li>{{ $t('number121') }}</li>
        </ul>

        <h4 class="subsection-title">{{ $t('number122') }}</h4>
        <ul>
          <li>{{ $t('number123') }}</li>
          <li>{{ $t('number124') }}</li>
          <li>{{ $t('number125') }}</li>
        </ul>

        <h4 class="subsection-title">{{ $t('number126') }}</h4>
        <ul>
          <li>{{ $t('number127') }}</li>
          <li>{{ $t('number128') }}</li>
          <li>{{ $t('number129') }}</li>
        </ul>
      </section>
    </transition>

    <transition name="fade" mode="out-in">
      <section class="technology card">
        <h3 class="section-title">{{ $t('number130') }}</h3>
        <p>{{ $t('number131') }}</p>
      </section>
    </transition>

    <transition name="fade" mode="out-in">
      <section class="facilities card">
        <h3 class="section-title">{{ $t('number132') }}</h3>
        <p>{{ $t('number133') }}</p>
      </section>
    </transition>

    <transition name="fade" mode="out-in">
      <section class="research card">
        <h3 class="section-title">{{ $t('number134') }}</h3>
        <p>{{ $t('number135') }}</p>
      </section>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'OncologyDepartment'
}
</script>

<style scoped>
.app {
  width: 90%;
  margin: 0 auto;
  padding: 30px;
  line-height: 1.6;
}

.header h1 {
  font-size: 3rem;
  color: #003366;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 600;
}

.main_h2{
  font-size: 1.8rem;
  text-align: center;
  font-weight: 400;
  margin-bottom: 40px;
}

.header h2 {
  font-size: 1.8rem;
  color: #003366;
  text-align: center;
  font-weight: 500;
  margin-bottom: 40px;
}

/* Card Styling */
.card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin-bottom: 40px;
  transition: all 0.3s ease;
}

/* Hover Effect */
.card:hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

/* Section Titles */
.section-title {
  font-size: 2rem;
  color: #003366;
  margin-bottom: 25px;
  font-weight: bold;
  text-align: center;
}

.jaro_p{
  text-align: center;
  font-size: 18px;
}

.jar_info{
  display: flex;
  justify-content: space-between;
}

.subsection-title {
  font-size: 1.6rem;
  color: #4b6f83;
  margin-bottom: 15px;
  font-weight: 500;
}

/* Lists */
ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 20px;
}

ul li {
  font-size: 1.1rem;
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

ul li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #2980b9;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .app {
    width: 95%;
    padding: 20px;
  }

  .header h1 {
    font-size: 2rem;
  }

  .header h2 {
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  .app {
    width: 100%;
    padding: 15px;
  }

  .header h1 {
    font-size: 1.8rem;
  }

  .header h2 {
    font-size: 1.4rem;
  }

  .jar_info {
    flex-direction: column;
  }
}

.transition-fade-enter-active,
.transition-fade-leave-active {
  transition: opacity 0.5s;
}

.transition-fade-enter, 
.transition-fade-leave-to {
  opacity: 0;
}
</style>
