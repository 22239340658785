<template>   
  <div class="container">
    <div class="reanimation-container">
      <header class="header text-center">
        <h1 class="main_h1 display-4 fade-in">{{ $t('anesthesiaResuscitationTitle890') }}</h1>
        <p class="lead fade-in">{{ $t('anesthesiaResuscitationDescription891') }}</p>
      </header>

      <div class="doctor-info-container">
        <div class="doctor-image">
          <img :src="img3" alt="Doctor's Photo" class="doctor-img">
        </div>
        <div class="doctor-details">
          <h1 class="doctor-name">{{ $t('doctorName440') }}</h1>
          <h2 class="doctor-position">{{ $t('doctorPositionUZ') }}</h2>
        </div>
      </div>

      <section class="overview">
        <h2 class="section-title fade-in">{{ $t('anesthesiaResuscitationAboutTitle892') }}</h2>
        <div class="overview-content">
          <p class="overview-text fade-in">{{ $t('anesthesiaResuscitationOverview893') }}</p>
          <ul class="services-list fade-in">
            <li>{{ $t('anesthesiaResuscitationItem1') }}</li>
            <li>{{ $t('anesthesiaResuscitationItem2') }}</li>
            <li>{{ $t('anesthesiaResuscitationItem3') }}</li>
          </ul>

          <p class="overview-text fade-in">{{ $t('anesthesiaResuscitationServiceTitle894') }}</p>
          <ul class="services-list fade-in">
            <li>{{ $t('anesthesiaResuscitationService1') }}</li>
            <li>{{ $t('anesthesiaResuscitationService2') }}</li>
            <li>{{ $t('anesthesiaResuscitationService3') }}</li>
            <li>{{ $t('anesthesiaResuscitationService4') }}</li>
            <li>{{ $t('anesthesiaResuscitationService5') }}</li>
          </ul>

          <p class="overview-text fade-in">{{ $t('anesthesiaResuscitationEquipmentTitle895') }}</p>
          <p class="overview-text fade-in">{{ $t('anesthesiaResuscitationEquipment896') }}</p>
          <p class="overview-text fade-in">{{ $t('anesthesiaResuscitationModernTherapy897') }}</p>
        </div>

        <div class="image-container fade-in">
          <img :src="img2" alt="Reanimatsiya Image" class="center-img">
        </div>

        <div class="image-container fade-in">
          <img :src="img1" alt="Reanimatsiya Image" class="center-img">
        </div>
      </section>

      <!-- Equipment Section -->
      <section class="equipment">
        <h2 class="section-title fade-in">{{ $t('anesthesiaResuscitationEquipmentTitle898') }}</h2>
        <div class="equipment-list fade-in">
          <div class="equipment-item hover-card">
            <h3>{{ $t('anesthesiaResuscitationSNA893') }}</h3>
            <p>{{ $t('anesthesiaResuscitationSNADescription899') }}</p>
          </div>
          <div class="equipment-item hover-card">
            <h3>{{ $t('anesthesiaResuscitationCardiomanitor900') }}</h3>
            <p>{{ $t('anesthesiaResuscitationCardiomanitorDescription901') }}</p>
          </div>
        </div>
      </section>

      <!-- Services Section -->
      <section class="services">
        <h2 class="section-title fade-in">{{ $t('anesthesiaResuscitationServices902') }}</h2>
        <ul class="services-list fade-in">
          <li>{{ $t('anesthesiaResuscitationService11') }}</li>
          <li>{{ $t('anesthesiaResuscitationService22') }}</li>
          <li>{{ $t('anesthesiaResuscitationService33') }}</li>
          <li>{{ $t('anesthesiaResuscitationService44') }}</li>
        </ul>
      </section>
    </div>
  </div>
</template>


  
  <script>
  import img2 from '@/assets/doctor/imggg1.jpg'
  import img1 from '@/assets/doctor/imggg2.jpg'
  import img3 from '@/assets/doctor/ochilov_xalim.jpg'
  export default {
    data(){
        return{
            img2,
            img1,
            img3
        }
    }
    
  };
  </script>
  
  <style scoped>
  .reanimation-container {
    padding: 0 20px;
    color: #333;
  }
  
  .header {
    margin-bottom: 40px;
  }
  
  h1, h2, h3 {
    font-weight: 600;
    color: #004e92;
  }
  
  .doctor-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px;
  }
  
  .doctor-image {
    margin-bottom: 20px;
    position: relative;
  }
  
  .doctor-img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
  }
  
  .doctor-img:hover {
    transform: scale(1.05);
  }
  
  .doctor-details {
    padding: 10px;
  }
  
  .doctor-name {
    font-size: 2rem;
    font-weight: 700;
    color: #004e92;
    margin: 10px 0;
  }
  
  .doctor-position {
    font-size: 1.5rem;
    font-weight: 500;
    color: #666;
  }
  
  h1 {
    font-size: 3rem;
  }
  
  h2 {
    font-size: 2.2rem;
  }
  
  h3 {
    font-size: 1.4rem;
  }
  
  .section-title {
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #004e92;
    font-size: 2.5rem;
    font-weight: 600;
    animation: fadeIn 1.5s ease;
  }
  
  .overview {
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
  }
  
  .overview-content {
    max-width: 900px;
    margin: 0 auto;
  }
  
  .overview-text {
    font-size: 1.2rem;
    line-height: 1.7;
    margin-bottom: 20px;
    color: #444;
    font-weight: 500;
  }
  
  .services-list {
    list-style-type: none;
    padding-left: 0;
    margin: 20px 0;
    font-size: 1.1rem;
  }
  
  .services-list li {
    background-color: #ffffff;
    padding: 15px;
    margin: 10px 0;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .services-list li:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
    background-color: #e1f5fe;
    color: #06829b;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .center-img {
    display: block;
    width: 80%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    animation: fadeIn 2s ease;
  }
  
  .head_h1 {
    color: #003366;
    font-size: 3rem;
    font-weight: 600;
  }
  
  .lead {
    font-size: 22px;
    font-weight: 500;
  }
  
  .equipment-list {
    display: flex;
    gap: 30px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .equipment-item {
    background-color: #ffffff;
    padding: 25px;
    width: 380px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: fadeIn 2s ease;
    margin: 20px 0;
  }
  
  .equipment-item:hover {
    transform: translateY(-8px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
  }
  
  .equipment-item h3 {
    font-size: 1.3rem;
    color: #06829b;
  }
  
  .equipment-item p {
    font-size: 1rem;
    color: #666;
  }
  
  /* Fade-in Animation */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .overview {
      padding: 20px;
    }
  
    .section-title {
      font-size: 2rem;
    }
  
    .overview-text {
      font-size: 1rem;
    }
  
    .services-list {
      font-size: 1rem;
    }
  
    .center-img {
      max-width: 100%;
    }
  
    .doctor-info-container {
      padding: 20px;
    }
  
    .doctor-name {
      font-size: 2rem;
    }
  
    .doctor-position {
      font-size: 1.2rem;
    }
  
    .doctor-img {
      width: 200px;
      height: 200px;
    }
  
    .equipment-item {
      width: 100%;
      padding: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .header {
      padding: 10px;
    }
  
    .doctor-name {
      font-size: 1.8rem;
    }
  
    .doctor-position {
      font-size: 1rem;
    }
  
    .section-title {
      font-size: 1.8rem;
    }
  
    .overview-text {
      font-size: 0.9rem;
    }
  
    .services-list li {
      font-size: 1rem;
    }
  
    .equipment-item {
      padding: 10px;
    }
  }
  </style>
  