<template>
  <div class="iv-surgery-department">
    <div class="container">
      <header class="header text-center mb-5">
        <p class="mainh1">{{ $t('departmentTitle740') }}</p>
        <p class="lead">{{ $t('departmentDescription741') }}</p>
      </header>

      <section class="overview">
        <p class="mainh2 text-center">{{ $t('aboutDepartment742') }}</p>
        <p>{{ $t('departmentInfo743') }}</p>
        <img :src="Jarrohlik" alt="Surgery Department" class="img-fluid mb-4 fade-in">
      </section>

      <section class="diagnostics mt-5">
        <p class="mainh2 text-center">{{ $t('diagnosticMethods744') }}</p>
        <p class="text-center">{{ $t('diagnosticInfo745') }}</p>
        <div class="row">
          <div class="col-md-6">
            <div class="service-card">
              <p class="mainh3">{{ $t('boneBiopsy746') }}</p>
              <p>{{ $t('boneBiopsyInfo747') }}</p>
              <img :src="img4" alt="Bone Biopsy" class="service-image fade-in">
            </div>
          </div>
          <div class="col-md-6">
            <div class="service-card">
              <p class="mainh3">{{ $t('histology748') }}</p>
              <p>{{ $t('histologyInfo749') }}</p>
              <img :src="img2" alt="Histology" class="service-image fade-in">
            </div>
          </div>
        </div>
      </section>

      <section class="surgery mt-5">
        <p class="mainh2 text-center">{{ $t('surgery751') }}</p>
        <p class="text-center">{{ $t('surgeryInfo752') }}</p>
        <div class="row">
          <div class="col-md-6">
            <div class="service-card">
              <p class="mainh3">{{ $t('endoprosthetics753') }}</p>
              <p>{{ $t('endoprostheticsInfo754') }}</p>
              <img :src="img3" alt="Endoprosthetics" class="service-image fade-in">
            </div>
          </div>
          <div class="col-md-6">
            <div class="service-card">
              <p class="mainh3">{{ $t('resection755') }}</p>
              <p>{{ $t('resectionInfo756') }}</p>
              <img :src="img1" alt="Resection Surgery" class="service-image fade-in">
            </div>
          </div>
        </div>
      </section>

      <section class="soft-tissue-sarcomas mt-5">
        <p class="mainh2 text-center">{{ $t('softTissueSarcomas757') }}</p>
        <p class="text-center">{{ $t('softTissueSarcomasInfo758') }}</p>
        <div class="row">
          <div class="col-md-6">
            <div class="service-card">
              <p class="mainh3">{{ $t('plasticSurgery759') }}</p>
              <p>{{ $t('plasticSurgeryInfo760') }}</p>
              <img :src="img5" alt="Soft Tissue Sarcoma" class="service-image fade-in">
            </div>
          </div>
          <div class="col-md-6">
            <div class="service-card">
              <p class="mainh3">{{ $t('rotationalFlap761') }}</p>
              <p>{{ $t('rotationalFlapInfo762') }}</p>
              <img :src="img7" alt="Rotational Flap Surgery" class="service-image fade-in">
            </div>
          </div>
        </div>
      </section>

      <section class="melanoma mt-5">
        <p class="mainh2 text-center">{{ $t('melanoma763') }}</p>
        <p class="text-center">{{ $t('melanomaInfo764') }}</p>
        <div class="row">
          <div class="col-md-6">
            <div class="service-card">
              <p class="mainh3">{{ $t('melanomaSurgery765') }}</p>
              <p>{{ $t('melanomaSurgeryInfo766') }}</p>
              <img :src="img6" alt="Melanoma Surgery" class="service-image fade-in">
            </div>
          </div>
          <div class="col-md-6">
            <div class="service-card">
              <p class="mainh3">{{ $t('lymphadenectomy767') }}</p>
              <p>{{ $t('lymphadenectomyInfo768') }}</p>
              <img :src="img8" alt="Lymphadenectomy" class="service-image fade-in">
            </div>
          </div>
        </div>
      </section>

      <section class="advanced-technologies mt-5">
        <p class="mainh2 text-center">{{ $t('advancedTech769') }}</p>
        <div class="row">
          <div class="col-md-4">
            <div class="facility-card">
              <p class="mainh3">{{ $t('msct770') }}</p>
              <p>{{ $t('msctInfo771') }}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="facility-card">
              <p class="mainh3">{{ $t('endoprothesis772') }}</p>
              <p>{{ $t('endoprothesisInfo773') }}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="facility-card">
              <p class="mainh3">{{ $t('plasticSurgeryTech774') }}</p>
              <p>{{ $t('plasticSurgeryTechInfo775') }}</p>
            </div>
          </div>
        </div>
      </section>

    </div>
  </div>
</template>
  
  <script>
  import Jarrohlik from '@/assets/nurli/jar4.jpg';
  import img1 from '@/assets/jarr4.1.jpg'
  import img2 from '@/assets/jarr4.2.jpg'
  import img3 from '@/assets/jar4.3.jpg'
  import img4 from '@/assets/jar4.4.jpg'
  import img5 from '@/assets/jar4.5.jpg'
  import img6 from '@/assets/jar4.6.jpg'
  import img7 from '@/assets/jar4.7.jpg'
  import img8 from '@/assets/jar4.8.jpg'

  export default {
    data(){
        return{
            Jarrohlik,
            img1,
            img2,
            img3,
            img4,
            img5,
            img6,
            img7,
            img8
        }
    }
  };
  </script>
  
  <style scoped>
  .iv-surgery-department {
    padding: 30px 0;
  }
 .mainh1{
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  color: #003366;
  margin-bottom: 20px;
 }

  .lead{
    font-size: 22px;
    font-weight: 500;
  }

  .mainh2{
  font-size: 2rem;

  }
  
  .mainh3 {
  font-size: 1.5rem;
  color: #333;
  }
  
  .service-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    margin: 20px 0;
    height: 500px;
  }
  .facility-card{
    background-color: #D4EAF2;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    margin: 20px 0;
    height: 200px;
  }
  
  .service-card:hover, .facility-card:hover {
    transform: translateY(-10px);
  }
  
  .service-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Image Styling */
  .service-image, .img-fluid {
    width: 100%;
    border-radius: 8px;
    transition: opacity 0.3s ease-in-out;
  }
  
  .fade-in {
    opacity: 0;
    animation: fadeIn 1.5s forwards;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .container {
    max-width: 1140px;
    margin: 0 auto;
  }
  
  .row {
    margin-bottom: 30px;
  }
  @media (max-width: 1024px) {
    .app-container {
      padding: 10px;
    }
  
    router-view {
      padding: 15px;
    }
  
    h1, h2, h3, p {
      font-size: 1.2rem;
    }
  
    router-view[name="header"], 
    router-view[name="footer"] {
      padding: 10px;
    }
  }
  
  @media (max-width: 768px) {
    .app-container {
      padding: 5px;
    }
  
    router-view {
      padding: 10px;
    }
  
    h1, h2, h3, p {
      font-size: 1rem;
    }
  
    .router-view {
      display: block;
      width: 100%;
    }
  
    router-view[name="header"], 
    router-view[name="footer"] {
      padding: 5px;
    }
  }
  
  @media (max-width: 480px) {
    .app-container {
      padding: 0;
    }
  
    router-view {
      padding: 5px;
    }
  
    h1, h2, h3, p {
      font-size: 0.9rem;
    }
  }
  </style>
  