<template>
  <div class="container">
    <div class="header text-center">
      <h1>{{$t('num1')}}</h1>
      <h2>{{$t('num2')}}</h2>
    </div>

    <div>
      <h2 class="text-center mt-5">{{$t('num3')}}</h2>
      <img :src="image1" alt="Laboratory Image" class="lab-img" />

      <div class="cards-container mt-5">
        <div class="card" v-for="(person, index) in people" :key="index">
          <div class="card-header">
            <img class="personal" :src="person.image" alt="Person Image" />
          </div>
          <div class="card-body">
            <h4>{{ person.name }}</h4>
            <p class="position">{{ person.role }}</p>
            <p class="contact">{{$t('num4')}} {{ person.phone }}</p>
          </div>
        </div>
      </div>
    </div>


    <div class="mt-5">
      <div>
        <h1 class="text-center">{{$t('num5')}}</h1>
      <h4 class="text-center py-3">{{$t('num6')}}</h4>
      </div>

      <div>
        <h3 class="text-center">{{$t('num7')}}</h3>
        <div class="d-flex justify-content-between flex-wrap p-5">
          <div>
            <p>{{$t('num8')}}</p>
            <p>{{$t('num9')}}</p>
            <p>{{$t('num10')}}</p>
            <p>{{$t('num11')}}</p>
            <p>{{$t('num12')}}</p>
            <p>{{$t('num13')}}</p>
          </div>
          <div>
            <p>{{$t('num14')}}</p>
            <p>{{$t('num15')}}</p>
            <p>{{$t('num16')}}</p>
            <p>{{$t('num17')}}</p>
            <p>{{$t('num18')}}</p>
            <p>{{$t('num19')}}</p>
            <p>{{$t('num20')}}</p>
            <p>{{$t('num21')}}</p>
          </div>
          <div>
            <p>{{$t('num22')}}</p>
            <p>{{$t('num23')}}</p>
            <p>{{$t('num24')}}</p>
            <p>{{$t('num25')}}</p>
            <p>{{$t('num26')}}</p>
            <p>{{$t('num27')}}</p>
            <p>{{$t('num28')}}</p>
            <p>{{$t('num29')}}</p>
            <p>{{$t('num30')}}</p>
          </div>          
        </div>
      </div>

      <div>
        <div>
          <h3 class="text-center">{{$t('num31')}}</h3>
          <div class="d-flex card2">
            <div><img class="img-titles" :src="im10" alt=""></div>
            <div class="subtitle">
              <h5>{{$t('num32')}}</h5>
            </div>
          </div>
        </div>
        <div>
          <h3 class="text-center mt-5">{{$t('num33')}}</h3>
          <div class="d-flex card2">
            <div><img class="img-titles" :src="im11" alt=""></div>
            <div class="subtitle">
              <h5>{{$t('num34')}}</h5>
            </div>
          </div>
        </div>
        <div>
          <h3 class="text-center mt-5">{{$t('num35')}}</h3>
          <div class="d-flex card2">
            <div><img class="img-titles" :src="im12" alt=""></div>
            <div class="subtitle">
              <h5>{{$t('num36')}}</h5>
            </div>
          </div>
          <div class="mt-5">
            <h3>{{$t('num37')}}</h3>
            <div class="d-flex card2">
              <div class="subtitle">
                <h5><span>{{$t('num38')}}</span> {{$t('num39')}}</h5>
                <h5><span>{{$t('num40')}}</span> {{$t('num41')}}</h5>
                <h5><span>{{$t('num42')}}</span> {{$t('num43')}}</h5>
                <h5><span>{{$t('num44')}}</span> {{$t('num45')}}</h5>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 class="text-center mt-5">{{$t('num46')}}</h3>
          <div class="d-flex card2">
            <div><img class="img-titles" :src="im13" alt=""></div>
            <div class="subtitle">
              <h5>{{$t('num47')}}</h5>
              <h5>{{$t('num48')}}</h5>
              <h5>{{$t('num49')}}</h5>
              <h5>{{$t('num50')}}</h5>
              <h5>{{$t('num51')}}</h5>
            </div>
          </div>
        </div>
        <div>
          <h3 class="text-center mt-5">{{$t('num52')}}</h3>
          <div class="d-flex card2">
            <div><img class="img-titles" :src="im14" alt=""></div>
            <div class="subtitle">
              <h5>{{$t('num53')}}</h5>
              <h5><span>{{$t('num54')}}</span> {{$t('num55')}}</h5>
              <h5><span>{{$t('num56')}}</span></h5>
              <h5>{{$t('num57')}}</h5>
            </div>
          </div>
        </div>
        <div>
          <h3 class="text-center mt-5">{{$t('num58')}}</h3>
          <div class="d-flex card2">
            <div><img class="img-titles" :src="im15" alt=""></div>
            <div class="subtitle">
              <h5>{{$t('num59')}}</h5>
            </div>
          </div>
        </div>
        <div>
          <h3 class="text-center mt-5">{{$t('num60')}}</h3>
          <div class="d-flex card2">
            <div><img class="img-titles" :src="im16" alt=""></div>
            <div class="subtitle">
              <h5>{{$t('num61')}}</h5>
              <h5>{{$t('num62')}}</h5>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <h1>{{$t('num63')}}</h1>
          <p>• {{$t('num64')}}</p>
          <p>• {{$t('num65')}}</p>
          <p>• {{$t('num66')}}</p>
          <p>• {{$t('num67')}}</p>
          <p>• {{$t('num68')}}</p>
        </div>
        <div class="mt-5">
          <h1 class="text-center">{{$t('num69')}}</h1>
          <div>
            <h3 class="text-center mt-5">{{$t('num70')}}</h3>
            <div class="d-flex card2">
              <div><img class="img-titles" :src="im17" alt=""></div>
              <div class="subtitle">
                <h5>{{$t('num71')}}</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5">
          <h3 class="text-center">{{$t('num72')}}</h3>
          <h6>{{$t('num73')}}</h6>
          <h6>{{$t('num74')}}</h6>
          <div>
            <p>• {{$t('num75')}}</p>
            <p>• {{$t('num76')}}</p>
            <p>• {{$t('num77')}}</p>
            <p>• {{$t('num78')}}</p>
            <p>• {{$t('num79')}}</p>
          </div>
          <div>
            <h3 class="text-center">{{$t('num80')}}</h3>
            <p>• {{$t('num81')}}</p>
            <p>• {{$t('num82')}}</p>
            <p>• {{$t('num83')}}</p>
            <p>• {{$t('num84')}}</p>
            <p>• {{$t('num85')}}</p>
            <p>• {{$t('num86')}}</p>
            <p>• {{$t('num87')}}</p>
            <p>• {{$t('num88')}}</p>
          </div>
        </div>
        <div>
          <h3 class="text-center mt-5">{{$t('num89')}}</h3>
          <div class="d-flex card2">
            <div><img class="img-titles" :src="im19" alt=""></div>
            <div class="subtitle">
              <h5>{{$t('num90')}}</h5>
              <h5>{{$t('num91')}}</h5>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex card2 mt-2">
            <div><img class="img-titles" :src="im20" alt=""></div>
            <div class="subtitle">
              <h5>{{$t('num92')}}</h5>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <h3>{{$t('num93')}}</h3>
          <h5>{{$t('num94')}}</h5>
        </div>
        <div>
          <h3 class="text-center mt-5">{{$t('num95')}}</h3>
          <div class="d-flex card2">
            <div><img class="img-titles" :src="im22" alt=""></div>
            <div class="subtitle">
              <h5>{{$t('num96')}}</h5>
              <h5>{{$t('num97')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

import im1 from '@/assets/labaratoriya/img1.png';
import im2 from '@/assets/labaratoriya/img2.png';
import im3 from '@/assets/labaratoriya/img3.png';
import im4 from '@/assets/labaratoriya/img4.png';
import im5 from '@/assets/labaratoriya/img5.png';
import im6 from '@/assets/labaratoriya/img6.png';
import im7 from '@/assets/labaratoriya/img7.png';
import im8 from '@/assets/labaratoriya/img8.png';
import im9 from '@/assets/labaratoriya/img9.png';
import im10 from '@/assets/labaratoriya/img10.png';
import im11 from '@/assets/labaratoriya/img11.png';
import im12 from '@/assets/labaratoriya/img12.png';
import im13 from '@/assets/labaratoriya/img13.png';
import im14 from '@/assets/labaratoriya/img14.png';
import im15 from '@/assets/labaratoriya/img15.png';
import im16 from '@/assets/labaratoriya/img16.png';
import im17 from '@/assets/labaratoriya/img17.png';
import im18 from '@/assets/labaratoriya/img18.png';
import im19 from '@/assets/labaratoriya/img19.png';
import im20 from '@/assets/labaratoriya/img20.png';
import im21 from '@/assets/labaratoriya/img21.png';
import im22 from '@/assets/labaratoriya/img22.png'


const image1 = ref(im1);
const image2 = ref(im2)
const image3 = ref(im11)
const image4 = ref(im12)
const image5 = ref(im13)
const image6 = ref(im14)
const image7 = ref(im15)
const image8 = ref(im16)
const image9 = ref(im17)
const image10 = ref(im18)
const image11 = ref(im19)
const image12 = ref(im20)
const image13 = ref(im21)
const image14 = ref(im22)



const people = ref([
  {
    name: 'Хамроев Махмуд Жумаевич',
    role: 'Заведующий отделением клинико-патоморфологической лаборатории врач- высшей категории',
    phone: '+998914052081',
    image: im2,
  },
  {
    name: 'Хамроева Муяссар Раззаковна',
    role: 'Врач-патологоанатом высшей категории ',
    phone: '+998913120049',
    image: im3,
  },
  {
    name: 'Рахмонова Камилла Эркиновна ',
    role: 'Врач-патологоанатом 2 категории ',
    phone: '+998906129509',
    image: im4,
  },
  {
    name: 'Абдуллаева Муаззам Нуриллаевна',
    role: 'Врач –цитолог Высшей категории ',
    phone: '+998905147888',
    image: im5,
  },
  {
    name: 'Муродова Маржона Мухаммедовна',
    role: 'Врач –патологоанатом  2 категории  ',
    phone: '+99891417 05 09 ',
    image: im6,
  },
  {
    name: 'Хамроева Лола Хамидовна',
    role: 'Врач –Лаборант Высшей категории',
    phone: '+99890 744 61 97',
    image: im7,
  },
  {
    name: 'Рузиева Феруза Отамурадовна ',
    role: 'Врач –Лаборант Высшей категории ',
    phone: '+99890 512 23 83',
    image: im8,
  },
  {
    name: 'Хусенов Собир Хамидович ',
    role: 'Врач-Лаборант',
    phone: '+99894 249 65 65 ',
    image: im9,
  },
]);
</script>

<style scoped>
.container {
  padding: 20px;
}

.header {
  margin-bottom: 40px;
}

.img-titles {
  width: 250px;
  height: 300px;
  border-radius: 8px;
}

.subtitle {
  width: 1000px;
  padding: 20px;
}

.lab-img {
  width: 100%;
  max-width: 1000px;
  display: block;
  margin: 20px auto;
}

.qon {
  width: 30%;
  height: 300px;
}

span {
  color: #333;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
}

.card2 {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid black;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  background: #06829B;
  border: none;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.card-header {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.personal {
  width: 300px;
  height: 190px;
  object-fit: cover;
  border-radius: 8px;
  object-position: top;
}

.card-body {
  padding: 15px;
  text-align: center;
}

.card-body h4 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.card-body .position {
  font-size: 14px;
  color: #777;
  margin-bottom: 10px;
}

.card-body .contact {
  font-size: 16px;
  color: #06829B;
  font-weight: bold;
}

.card-body .contact:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .header h1, .header h2 {
    font-size: 1.5rem;
  }

  .cards-container {
    grid-template-columns: 1fr 1fr;
  }

  .img-titles {
    width: 200px;
    height: 250px;
  }

  .subtitle {
    width: 100%;
  }

  .card-body h4 {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .header h1, .header h2 {
    font-size: 1.2rem;
  }

  .cards-container {
    grid-template-columns: 1fr;
  }

  .img-titles {
    width: 180px;
    height: 220px;
  }

  .card-body h4 {
    font-size: 14px;
  }

  .card-body .position,
  .card-body .contact {
    font-size: 12px;
  }

  .card-body .contact:hover {
    font-size: 13px;
  }

  .card2 {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background: #06829B;
  }

  .img-titles {
    width: 100%;
    height: 180px;
  }

  .subtitle {
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  .subtitle h5 {
    font-size: 14px;
  }

  .card-body {
    padding: 10px;
  }

  .card-body h4 {
    font-size: 14px;
  }

  .card-body .position {
    font-size: 10px;
  }

  .card-body .contact {
    font-size: 12px;
  }
}

</style>
