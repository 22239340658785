<template>
    <div class="container">
      <div class="doctor-profile">
        <div class="image-section">
          <img
            class="doctor-image"
            :src="KhasanovaImage"
            alt="Doctor"
          />
        </div>
        <div class="info-section">
          <p class="mainh1">{{ $t('doctorName1111') }}</p>
          <p class="mainh2">{{ $t('specialty1112') }}</p>
          <p class="mainh3">{{ $t('position1113') }}</p>
          <p class="mainh3">{{ $t('phone1114') }}</p>
          <p class="mainh3">{{ $t('email1115') }}</p>
  
          <div class="info-block">
            <p class="mainh2">{{ $t('work1116') }}</p>
            <p>{{ $t('workPlace') }}</p>
          </div>
  
          <div class="info-block">
            <p class="mainh2">{{ $t('education1117') }}</p>
            <ul>
              <li>{{ $t('educationList1118') }}</li>
              <li>{{ $t('educationList1119') }}</li>
              <li>{{ $t('educationList1120') }}</li>
            </ul>
          </div>
  
          <div class="info-block">
            <p class="mainh2">{{ $t('professionalDevelopment1121') }}</p>
            <ul>
              <li>{{ $t('professionalDevelopmentList1122') }}</li>
            </ul>
          </div>
  
          <div class="info-block">
            <p class="mainh2">{{ $t('internationalExperience1123') }}</p>
            <ul>
              <li>{{ $t('internationalExperienceList1124') }}</li>
              <li>{{ $t('internationalExperienceList1125') }}</li>
            </ul>
          </div>
  
          <div class="info-block">
            <p class="mainh2">{{ $t('specialization1126') }}</p>
            <ul>
              <li>{{ $t('specializationList1127') }}</li>
              <li>{{ $t('specializationList1128') }}</li>
              <li>{{ $t('specializationList1129') }}</li>
              <li>{{ $t('specializationList1130') }}</li>
              <li>{{ $t('specializationList1131') }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import KhasanovaImage from '../../assets/doctor/hamroyevMaxmud.png'
  export default {
    data() {
      return {
        KhasanovaImage
      };
    }
  };
  </script>
  
  <style scoped>
  .doctor-profile {
    display: flex;
    gap: 20px;
    padding: 20px;
  }
  
  .image-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .doctor-image {
    border-radius: 20px;
    border: 5px solid #06829b;
    height: 600px;
    margin-bottom: 200px;
  }
  
  .info-section {
    flex: 2;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .mainh1 {
    font-size: 2.5rem;
    color: #003366;
  }
  
  .mainh2 {
    font-size: 2rem;
    color: #003366;
  }
  
  .mainh3 {
    color: #333;
    font-size: 1rem;
  }
  
  .info-block {
    margin-top: 20px;
  }
  
  .info-block h2 {
    font-size: 22px;
    color: #003366;
    margin-bottom: 10px;
  }
  
  .info-block ul {
    list-style-type: disc;
    margin-left: 20px;
    color: #333;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .doctor-profile {
      flex-direction: column;
      align-items: center;
    }
  
    .doctor-image {
      max-width: 100%;
      margin-bottom: 20px;
    }
  
    .info-section {
      width: 100%;
    }
  
    .mainh1 {
      font-size: 2rem;
    }
  
    .mainh2 {
      font-size: 1.6rem;
    }
  
    .info-block h2 {
      font-size: 18px;
    }
  
    .info-block ul {
      margin-left: 15px;
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .mainh1 {
      font-size: 1.8rem;
    }
  
    .doctor-image {
      width: 400px;
      height: 500px;
    }
  
    .mainh2 {
      font-size: 1.4rem;
    }
  
    .info-block h2 {
      font-size: 16px;
    }
  
    .info-block ul {
      font-size: 0.9rem;
    }
  }
  </style>
  