<template>
  <div class="container">
      <div class="radiotherapy-container">
          <header class="header text-center">
            <h1 class="display-4 fade-in head_h1">{{ $t('radiotherapyTitle880') }}</h1>
            <p class="lead fade-in">{{ $t('radiotherapyDescription881') }}</p>
          </header>
      
          <section class="overview">
            <h2 class="section-title fade-in">{{ $t('radiotherapySectionTitle882') }}</h2>
            <p class="fade-in">{{ $t('radiotherapyOverviewDescription883') }}</p>
            <img :src="img2" alt="Radioterapiya Image" class="center-img">
          </section>
      
          <section class="equipment">
            <h2 class="section-title fade-in">{{ $t('radiotherapyEquipmentTitle884') }}</h2>
            <div class="equipment-list fade-in">
              <div class="equipment-item hover-card">
                <h3>{{ $t('gammaTherapyEquipment885') }}</h3>
                <p>{{ $t('gammaTherapyDescription886') }}</p>
              </div>
              <div class="equipment-item hover-card">
                <h3>{{ $t('brachytherapyEquipment887') }}</h3>
                <p>{{ $t('brachytherapyDescription888') }}</p>
              </div>
            </div>
          </section>
      
          <section class="treated-cancers">
            <h2 class="section-title fade-in">{{ $t('radiotherapyTreatedCancersTitle889') }}</h2>
            <ul class="cancer-types fade-in">
              <li>{{ $t('cancerType1') }}</li>
              <li>{{ $t('cancerType2') }}</li>
              <li>{{ $t('cancerType3') }}</li>
              <li>{{ $t('cancerType4') }}</li>
              <li>{{ $t('cancerType5') }}</li>
              <li>{{ $t('cancerType6') }}</li>
              <li>{{ $t('cancerType7') }}</li>
              <li>{{ $t('cancerType8') }}</li>
              <li>{{ $t('cancerType9') }}</li>
              <li>{{ $t('cancerType10') }}</li>
              <li>{{ $t('cancerType11') }}</li>
              <li>{{ $t('cancerType12') }}</li>
              <li>{{ $t('cancerType13') }}</li>
              <li>{{ $t('cancerType14') }}</li>
              <li>{{ $t('cancerType15') }}</li>
              <li>{{ $t('cancerType16') }}</li>
              <li>{{ $t('cancerType17') }}</li>
              <li>{{ $t('cancerType18') }}</li>
            </ul>
          </section>
      </div>
  </div>
</template>

  
<script>
import img2 from '@/assets/image6.jpg'; 
  
export default {
  data() {
    return {
      img2,
    };
  },
};
</script>
  
<style scoped>
.radiotherapy-container {
  padding: 60px 20px;
  color: #333;
}

.header {
  margin-bottom: 40px;
}

h1,
h2,
h3 {
  font-weight: 600;
  color: #004e92; 
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.2rem;
}

h3 {
  font-size: 1.4rem;
}

.section-title {
  margin-bottom: 25px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #333;
  font-size: 2rem;
  font-weight: 600;
  animation: fadeIn 1.5s ease;
}

.cancer-types {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  font-size: 1.1rem;
  margin: 20px 0;
  text-align: left;
}

.cancer-types li {
  background-color: #f4f6f9;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.cancer-types li:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
  background-color: #e1f5fe; /* Light blue hover effect */
  color: #06829b; /* Text color change on hover */
}

.head_h1{
  color: #003366;
  font-size: 3rem;
  font-weight: 600;
}

.lead{
  font-size: 22px;
  font-weight: 500;
}

.center-img {
  display: block;
  margin: 40px auto;
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  animation: fadeIn 1.5s ease;
}

.equipment-list {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}

.equipment-item {
  background-color: #ffffff;
  padding: 25px;
  width: 380px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeIn 2s ease;
  margin: 20px 0;
}

.equipment-item:hover {
  transform: translateY(-8px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
}

.equipment-item h3 {
  font-size: 1.3rem;
  color: #06829b;
}

.equipment-item p {
  font-size: 1rem;
  color: #666;
}

.hover-card:hover {
  cursor: pointer;
  transform: translateY(-10px);
  box-shadow: 0 20px 45px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .radiotherapy-container {
    padding: 20px;
  }

  h1 {
    font-size: 2.2rem; /* Adjusting font size for smaller screens */
  }

  h2 {
    font-size: 1.8rem;
  }

  .section-title {
    font-size: 1.6rem;
  }

  .cancer-types {
    grid-template-columns: 1fr; /* Single column for phones */
    margin-bottom: 15px;
  }

  .cancer-types li {
    font-size: 1rem; /* Adjusting the font size for better readability */
    padding: 12px;
  }

  .center-img {
    max-width: 100%;
  }

  .equipment-item {
    width: 100%;
    margin-bottom: 15px;
  }

  .equipment-item h3 {
    font-size: 1.2rem;
  }

  .equipment-item p {
    font-size: 0.9rem;
  }
}

/* Medium screens (tablets, iPads, and larger phones) */
@media (max-width: 768px) {
  .radiotherapy-container {
    padding: 40px 20px;
  }

  h1 {
    font-size: 2.5rem; /* Slightly smaller font for iPads */
  }

  h2 {
    font-size: 2rem;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .cancer-types {
    grid-template-columns: 1fr 1fr; /* Two columns for tablets */
  }

  .cancer-types li {
    font-size: 1.1rem;
  }

  .equipment-item {
    width: 100%;
    margin-bottom: 25px; /* Adding spacing between items on tablets */
  }

  .equipment-item h3 {
    font-size: 1.3rem;
  }

  .equipment-item p {
    font-size: 1rem;
  }
}

/* Large Tablets (iPad and bigger) */
@media (min-width: 769px) and (max-width: 1024px) {
  .radiotherapy-container {
    padding: 50px 20px;
  }

  h1 {
    font-size: 2.8rem; /* Slightly larger for tablets */
  }

  .cancer-types {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Allowing more items per row */
  }

  .cancer-types li {
    font-size: 1.1rem;
  }
}
</style>
