import { createRouter, createWebHistory } from 'vue-router'
import AppHeader from "@/layout/AppHeader.vue";
import AppFooter from "@/layout/AppFooter.vue";
import Components from "../views/Components.vue";
import about from '@/views/about/index.vue';
import uchqun from '@/views/doctorlar/uchunAbdumalikov.vue';
import erkin from '@/views/doctorlar/erkinAbdullayev.vue';
import saodat from '@/views/doctorlar/saodatAbdullaeva.vue';
import dilnoz from '@/views/doctorlar/dilnozaAmrullayeva.vue';
import baxriddin from '@/views/doctorlar/asadovBaxriddin.vue';
import anvar from '@/views/doctorlar/TolayevAnvar.vue';
import yahe from '@/views/doctorlar/HakiovYahe.vue';
import shaxob from '@/views/doctorlar/azimovShahob.vue';
import bafo from '@/views/doctorlar/bafoYoldashev.vue';
import shukur from '@/views/doctorlar/shukurBaqoev.vue';
import adham from '@/views/doctorlar/barnoevAdham.vue';
import shuxrat from '@/views/doctorlar/shuhratBoboev.vue';
import mashrab from '@/views/doctorlar/mashrabBoltaev.vue';
import eler from '@/views/doctorlar/elerChoriev.vue';
import furqat from '@/views/doctorlar/FurqatHAydarov.vue';
import dildora from '@/views/doctorlar/DildoraIshanqulova.vue';
import muharram from '@/views/doctorlar/MuxarramIsmoilova.vue';
import doniyor from '@/views/doctorlar/DoniyorIzzatillayev.vue';
import Karimov from '@/views/doctorlar/MuattarKarimov.vue';
import shahob from '@/views/doctorlar/shahobQoshayev.vue';
import Baxrom from '@/views/doctorlar/LatipovBaxrom.vue';
import Nasiba from '@/views/doctorlar/NasibaMaqsudova.vue';
import Umid from '@/views/doctorlar/UmidMamedov.vue';
import Yusuf from '@/views/doctorlar/YusufMurodov.vue';
import Laylo from '@/views/doctorlar/LayloMUhiddinova.vue';
import Fahriddin from '@/views/doctorlar/FahriddinMuzaffarov.vue';
import Firuza from '@/views/doctorlar/FiruzaNabiyeva.vue';
import Bekzod from '@/views/doctorlar/NaimovBekzod.vue';
import Nurbek from '@/views/doctorlar/NazarovNurbek.vue';
import Jahongir from '@/views/doctorlar/NematjonovJahongir.vue';
import Halim from '@/views/doctorlar/OchilovHalim.vue';
import Dilsuz from '@/views/doctorlar/DilsuzAtaboyeva.vue';
import Bekzodd from '@/views/doctorlar/RAximovBekzod.vue';
import Akmal from '@/views/doctorlar/RoziyevAkmal.vue';
import Feruza from '@/views/doctorlar/RoziyevaFeruza.vue';
import Shahlo from '@/views/doctorlar/RoziyevaShahlo.vue';
import Fayzullo from '@/views/doctorlar/SalihovFayzullo.vue';
import Abdulaziz from '@/views/doctorlar/ShamsiddinovAbdulaziz.vue';
import Mirshod from '@/views/doctorlar/SharipovMirshod.vue';
import Mustafo from '@/views/doctorlar/ShirinovMustafo.vue';
import Zamon from '@/views/doctorlar/ShukurovZamon.vue';
import Jorabek from '@/views/doctorlar/TogaboyevJorabek.vue';
import Bobir from '@/views/doctorlar/BobirErgashev.vue';
import Nodir from '@/views/doctorlar/NodirErgashev.vue';
import Muhmud from '@/views/doctorlar/HamroboyevMuhmud.vue';
import Muyyassar from '@/views/doctorlar/HamroeyaMuyyassar.vue';
import Gulhayo from '@/views/doctorlar/HamroqulovaGulhayo.vue';
import Nasibaa from '@/views/doctorlar/HasanovaNasiba.vue';
import Alimardon from '@/views/doctorlar/HAyitovAlimardon.vue';
import Nulufar from '@/views/doctorlar/NilufarJamolova.vue'
import firstnews from '@/views/yangiliklar/firstnews.vue';
import secondnews from '@/views/yangiliklar/secondnews.vue';
import thirdnews from '@/views/yangiliklar/thirdnews.vue';
import tourth from '@/views/yangiliklar/tourth.vue';
import fifth from '@/views/yangiliklar/fifth.vue';
import six from '@/views/yangiliklar/six.vue';
import sevent from '@/views/yangiliklar/sevent.vue';
import eighth from '@/views/yangiliklar/eighth.vue';
import Jarrohlik from '@/views/jarrohlik/1Jarrohlik.vue';
import Jarrohlik2 from '@/views/jarrohlik/2Jarrohlik.vue';
import Jaroohlik from '@/views/jarrohlik/3Jaroohlik.vue';
import Jarrohlik4 from '@/views/jarrohlik/4Jarrohlik.vue';
import Labaratoriya from '@/views/jarrohlik/Labaratoriya.vue';
import NurliDiagnostika from '@/views/jarrohlik/NurliDiagnostika.vue';
import Poliayiv from '@/views/jarrohlik/Poliayiv.vue';
import Poliklinika from '@/views/jarrohlik/Poliklinika.vue';
import Radioterapiya from '@/views/jarrohlik/Radioterapiya.vue';
import Ximiyaterapiya from '@/views/jarrohlik/Ximiyaterapiya.vue';
import Reanimatsiya from '@/views/jarrohlik/Reanimatsiya.vue';
import BizHaqimizda from '@/views/BizHaqimizda.vue';
import Boshqaruv from '@/views/Boshqaruv.vue';
import Information from '@/views/Information.vue';
import Shifokorlar from '@/views/Shifokorlar.vue';
import firstpage from '@/views/xizmatlar/firstpage.vue';
import ikkinchi from '@/views/xizmatlar/ikkinchi.vue'
import uchunchi from '@/views/xizmatlar/uchunchi.vue';
import tortinchi from '@/views/xizmatlar/tortinchi.vue';
import beshinchi from '@/views/xizmatlar/beshinchi.vue';
import oltinchi from '@/views/xizmatlar/oltinchi.vue';
import xizmatlar from '@/views/Xizmatlar.vue';
import yangiliklar from '@/views/yangiliklar.vue';
import SalimovBotir from '@/views/doctorlar/SalimovBotir.vue';
import SarvarovMalik from '@/views/doctorlar/SarvarovMalik.vue';
import Shaxboz from '@/views/doctorlar/Shaxboz.vue';
import Azam from '@/views/doctorlar/Azam.vue';
import FahriddinMuzaffarov from '@/views/doctorlar/FahriddinMuzaffarov.vue';
import Odilov from '@/views/doctorlar/Odilov.vue';
import Sherzod from '@/views/doctorlar/Sherzod.vue';
import Qodil from '@/views/doctorlar/Qodil.vue';
import Lola from '@/views/doctorlar/sultonovaLola.vue';
import Shuxrat from '@/views/doctorlar/toxtayev.vue';
import Galdiyev from '@/views/doctorlar/majid.vue';
import hamroyevMahmud from '@/views/doctorlar/hamroyevMahmud.vue';
import hamroyevMuyassar from '@/views/doctorlar/hamroyevMuyassar.vue';
import abdullayevaMuazzam from '@/views/doctorlar/abdullayevaMuazzam.vue';
import murodovaMarjona from '@/views/doctorlar/murodovaMarjona.vue';
import raximovaKamila from '@/views/doctorlar/raximovaKamila.vue';
import Feruzaa from '@/views/doctorlar/Feruza.vue';
import hamidovaLola from '@/views/doctorlar/hamidovaLola.vue';
import azimovaDildora from '@/views/doctorlar/azimovaDildora.vue';
import raxmatovaShaxnoza from '@/views/doctorlar/raxmatovaShaxnoza.vue';

const routes = [

  {
    path: "/raxmatovaShaxnoza",
    name: "raxmatovaShaxnoza",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: raxmatovaShaxnoza,
      footer: AppFooter,
    }
  },

  {
    path: "/azimovaDildora",
    name: "azimovaDildora",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: azimovaDildora,
      footer: AppFooter,
    }
  },

  {
    path: "/hamidovaLola",
    name: "hamidovaLola",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: hamidovaLola,
      footer: AppFooter,
    }
  },

  {
    path: "/Feruzaa",
    name: "Feruzaa",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Feruzaa,
      footer: AppFooter,
    }
  },

  {
    path: "/raximovaKamila",
    name: "raximovaKamila",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: raximovaKamila,
      footer: AppFooter,
    }
  },

  {
    path: "/murodovaMarjona",
    name: "murodovaMarjona",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: murodovaMarjona,
      footer: AppFooter,
    }
  },

  {
    path: "/abdullayevaMuazzam",
    name: "abdullayevaMuazzam",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: abdullayevaMuazzam,
      footer: AppFooter,
    }
  },

  {
    path: "/hamroyevMuyassar",
    name: "hamroyevMuyassar",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: hamroyevMuyassar,
      footer: AppFooter,
    }
  },

  {
    path: "/hamroyevMahmud",
    name: "hamroyevMahmud",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: hamroyevMahmud,
      footer: AppFooter,
    }
  },

  {
    path: "/Galdiyev",
    name: "Galdiyev",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Galdiyev,
      footer: AppFooter,
    }
  },

  {
    path: "/Shuxrat",
    name: "Shuxrat",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Shuxrat,
      footer: AppFooter,
    }
  },

  {
    path: "/Lola",
    name: "Lola",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Lola,
      footer: AppFooter,
    }
  },

  {
    path: "/Qobil",
    name: "Qobil",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Qodil,
      footer: AppFooter,
    }
  },

  {
    path: "/Sherzod",
    name: "Sherzod",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Sherzod,
      footer: AppFooter,
    }
  },

  {
    path: "/Temirov",
    name: "Temirov",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Odilov,
      footer: AppFooter,
    }
  },

  {
    path: "/FahriddinMuzaffarov",
    name: "FahriddinMuzaffarov",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: FahriddinMuzaffarov,
      footer: AppFooter,
    }
  },

  {
    path: "/Azam",
    name: "Azam",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Azam,
      footer: AppFooter,
    }
  },

  {
    path: "/TorayevShaxboz",
    name: "TorayevShaxboz",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Shaxboz,
      footer: AppFooter,
    }
  },

  {
    path: "/SarvarovMalik",
    name: "SarvarovMalik",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: SarvarovMalik,
      footer: AppFooter,
    }
  },

  {
    path: "/SalimovBotir",
    name: "SalimovBotir",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: SalimovBotir,
      footer: AppFooter,
    }
  },

  {
    path: "/news",
    name: "news",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: yangiliklar,
      footer: AppFooter,
    }
  },
  {
    path: "/services",
    name: "services",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: xizmatlar,
      footer: AppFooter,
    }
  },
  {
    path: "/sixthpage",
    name: "sixthpage",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: oltinchi,
      footer: AppFooter,
    }
  },
  {
    path: "/fifthpage",
    name: "fifthpage",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: beshinchi,
      footer: AppFooter,
    }
  },
  {
    path: "/fourthpage",
    name: "fourthpage",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: tortinchi,
      footer: AppFooter,
    }
  },
  {
    path: "/thirdpage",
    name: "thirdpage",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: uchunchi,
      footer: AppFooter,
    }
  },
  {
    path: "/secondpage",
    name: "secondpage",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: ikkinchi,
      footer: AppFooter,
    }
  },
  {
    path: "/firstpage",
    name: "firstpage",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: firstpage,
      footer: AppFooter,
    }
  },
  {
    path: "/Information",
    name: "Information",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Information,
      footer: AppFooter,
    }
  },
  {
    path: "/doctors",
    name: "doctors",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Shifokorlar,
      footer: AppFooter,
    }
  },
  {
    path: "/management",
    name: "management",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Boshqaruv,
      footer: AppFooter,
    }
  },
  {
    path: "/aboutus",
    name: "aboutus",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: BizHaqimizda,
      footer: AppFooter,
    }
  },








  {
    path: "/",
    name: "components",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Components,
      footer: AppFooter,
    }
  },
  {
    path: "/about",
    name: "about",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: about,
      footer: AppFooter,
    }
  },
  {
    path: "/uchqun",
    name: "uchqun",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: uchqun,
      footer: AppFooter,
    }
  },
  {
    path: "/erkin",
    name: "erkin",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: erkin,
      footer: AppFooter,
    }
  },
  {
    path: "/saodat",
    name: "saodat",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: saodat,
      footer: AppFooter,
    }
  },
  {
    path: "/dilnoz",
    name: "dilnoz",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: dilnoz,
      footer: AppFooter,
    }
  },
  {
    path: "/baxriddin",
    name: "baxriddin",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: baxriddin,
      footer: AppFooter,
    }
  },
  {
    path: "/anvar",
    name: "anvar",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: anvar,
      footer: AppFooter,
    }
  },
  {
    path: "/yahe",
    name: "yahe",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: yahe,
      footer: AppFooter,
    }
  },
  {
    path: "/shaxob",
    name: "shaxob",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: shaxob,
      footer: AppFooter,
    }
  },
  {
    path: "/bafo",
    name: "bafo",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: bafo,
      footer: AppFooter,
    }
  },
  {
    path: "/shukur",
    name: "shukur",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: shukur,
      footer: AppFooter,
    }
  },
  {
    path: "/adham",
    name: "adham",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: adham,
      footer: AppFooter,
    }
  },
  {
    path: "/shuxrat",
    name: "shuxrat",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: shuxrat,
      footer: AppFooter,
    }
  },
  {
    path: "/mashrab",
    name: "mashrab",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: mashrab,
      footer: AppFooter,
    }
  },
  {
    path: "/eler",
    name: "eler",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: eler,
      footer: AppFooter,
    }
  },
  {
    path: "/furqat",
    name: "furqat",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: furqat,
      footer: AppFooter,
    }
  },
  {
    path: "/dildora",
    name: "dildora",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: dildora,
      footer: AppFooter,
    }
  },
  {
    path: "/muharram",
    name: "muharram",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: muharram,
      footer: AppFooter,
    }
  },
  {
    path: "/doniyor",
    name: "doniyor",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: doniyor,
      footer: AppFooter,
    }
  },
  {
    path: "/karimov",
    name: "karimov",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Karimov,
      footer: AppFooter,
    }
  },
  {
    path: "/shahob",
    name: "shahob",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: shahob,
      footer: AppFooter,
    }
  },
  {
    path: "/baxrom",
    name: "baxrom",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Baxrom,
      footer: AppFooter,
    }
  },
  {
    path: "/nasiba",
    name: "nasiba",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Nasiba,
      footer: AppFooter,
    }
  },
  {
    path: "/umid",
    name: "umid",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Umid,
      footer: AppFooter,
    }
  },
  {
    path: "/yusuf",
    name: "yusuf",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Yusuf,
      footer: AppFooter,
    }
  },
  {
    path: "/laylo",
    name: "laylo",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Laylo,
      footer: AppFooter,
    }
  },
  {
    path: "/nasibaa",
    name: "nasibaa",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Nasibaa,
      footer: AppFooter,
    }
  },
  {
    path: "/fahriddin",
    name: "fahriddin",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Fahriddin,
      footer: AppFooter,
    }
  },
  {
    path: "/firuza",
    name: "firuza",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Firuza,
      footer: AppFooter,
    }
  },
  {
    path: "/bekzod",
    name: "bekzod",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Bekzod,
      footer: AppFooter,
    }
  },
  {
    path: "/nurbek",
    name: "nurbek",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Nurbek,
      footer: AppFooter,
    }
  },
  {
    path: "/jahongir",
    name: "jahongir",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Jahongir,
      footer: AppFooter,
    }
  },
  {
    path: "/halim",
    name: "halim",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Halim,
      footer: AppFooter,
    }
  },
  {
    path: "/dilsuz",
    name: "dilsuz",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Dilsuz,
      footer: AppFooter,
    }
  },
  {
    path: "/bekzodd",
    name: "bekzodd",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Bekzodd,
      footer: AppFooter,
    }
  },
  {
    path: "/akmal",
    name: "akmal",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Akmal,
      footer: AppFooter,
    }
  },
  {
    path: "/feruza",
    name: "feruza",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Feruza,
      footer: AppFooter,
    }
  },
  {
    path: "/shahlo",
    name: "shahlo",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Shahlo,
      footer: AppFooter,
    }
  },
  {
    path: "/fayzullo",
    name: "fayzullo",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Fayzullo,
      footer: AppFooter,
    }
  },
  {
    path: "/abdulaziz",
    name: "abdulaziz",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Abdulaziz,
      footer: AppFooter,
    }
  },
  {
    path: "/mirshod",
    name: "mirshod",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Mirshod,
      footer: AppFooter,
    }
  },
  {
    path: "/mustafo",
    name: "mustafo",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Mustafo,
      footer: AppFooter,
    }
  },
  {
    path: "/zamon",
    name: "zamon",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Zamon,
      footer: AppFooter,
    }
  },
  {
    path: "/jorabek",
    name: "jorabek",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Jorabek,
      footer: AppFooter,
    }
  },
  {
    path: "/bobir",
    name: "bobir",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Bobir,
      footer: AppFooter,
    }
  },
  {
    path: "/nodir",
    name: "nodir",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Nodir,
      footer: AppFooter,
    }
  },
  {
    path: "/muhmud",
    name: "muhmud",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Muhmud,
      footer: AppFooter,
    }
  },
  {
    path: "/muyyassar",
    name: "muyyassar",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Muyyassar,
      footer: AppFooter,
    }
  },
  {
    path: "/gulhayo",
    name: "gulhayo",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Gulhayo,
      footer: AppFooter,
    }
  },
  {
    path: "/nasibaa",
    name: "nasibaa",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Nasibaa,
      footer: AppFooter,
    }
  },
  {
    path: "/alimardon",
    name: "alimardon",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Alimardon,
      footer: AppFooter,
    }
  },
  {
    path: "/nilufar",
    name: "nilufar",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Nulufar,
      footer: AppFooter,
    }
  },
  {
    path: "/firstnews",
    name: "firstnews",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: firstnews,
      footer: AppFooter,
    }
  },
  {
    path: "/secondnews",
    name: "secondnews",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: secondnews,
      footer: AppFooter,
    }
  },
  {
    path: "/thirdnews",
    name: "thirdnews",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: thirdnews,
      footer: AppFooter,
    }
  },
  {
    path: "/fourthnews",
    name: "fourthnews",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: tourth,
      footer: AppFooter,
    }
  },
  {
    path: "/fifthnews",
    name: "fifthnews",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: fifth,
      footer: AppFooter,
    }
  },
  {
    path: "/sixthnews",
    name: "sixthnews",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: six,
      footer: AppFooter,
    }
  },
  {
    path: "/seventhnews",
    name: "seventhnews",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: sevent,
      footer: AppFooter,
    }
  },
  {
    path: "/eightnews",
    name: "eightnews",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: eighth,
      footer: AppFooter,
    }
  },
  {
    path: "/surgeryI",
    name: "surgeryI",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Jarrohlik,
      footer: AppFooter,
    }
  },
  {
    path: "/surgeryII",
    name: "surgeryII",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Jarrohlik2,
      footer: AppFooter,
    }
  },
  {
    path: "/surgeryIII",
    name: "surgeryIII",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Jaroohlik,
      footer: AppFooter,
    }
  },
  {
    path: "/laboratoryPathology",
    name: "laboratoryPathologyy",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Labaratoriya,
      footer: AppFooter,
    }
  },
  {
    path: "/radiologyDiagnosis",
    name: "radiologyDiagnosis",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: NurliDiagnostika,
      footer: AppFooter,
    }
  },
  {
    path: "/palliativeCaree",
    name: "palliativeCaree",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Poliayiv,
      footer: AppFooter,
    }
  },
  {
    path: "/ximiyaterapiya",
    name: "ximiyaterapiya",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Ximiyaterapiya,
      footer: AppFooter,
    }
  },
  {
    path: "/radioterapiya",
    name: "radioterapiya",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Radioterapiya,
      footer: AppFooter,
    }
  },
  {
    path: "/polyclinic",
    name: "polyclinic",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Poliklinika,
      footer: AppFooter,
    }
  },
  {
    path: "/SurgeryVI",
    name: "SurgeryVI",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Jarrohlik4,
      footer: AppFooter,
    }
  },
  {
    path: "/resuscitation",
    name: "resuscitation",
    meta: { redirectIfLoggedIn: true },
    components: {
      header: AppHeader,
      default: Reanimatsiya,
      footer: AppFooter,
    }
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: () => ({ y: 0, x: 0 }),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path !== '/' && !to.meta.redirectIfLoggedIn) {
    if (localStorage.getItem('user_info')) {
      next();
    } else {
      next('/');
    }
  } else {
    next();
  }
});
export default router;
